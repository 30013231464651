import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';

import { environment } from '../environments/environment';

declare var sessionStorage: any;

export class AppSettings {
    private static readonly OTK_VERSION = 'OTK_VERSION';
    private static readonly OTK_MENU_OPEN = 'OTK_MENU_OPEN';
    private static readonly OTK_REMEMBER_EMAIL = 'OTK_REMEMBER_EMAIL';
    private static readonly OTK_REMEMBER_TOKEN = 'OTK_REMEMBER_TOKEN';
    private static readonly OTK_CLICK_VERSION = 'OTK_CLICK_VERSION';

    public static readonly TOKEN_SAS_DEV =
        '?sv=2022-11-02&ss=b&srt=co&sp=r&se=2025-09-01T03:00:00Z&st=2023-09-01T03:00:00Z&spr=https&sig=yKPAP1%2B63a4NTAXLCBFm8NgfMmqxbYYm7wizEUirWl4%3D';

    public static readonly TOKEN_SAS_PROD =
        '?sv=2022-11-02&ss=b&srt=co&sp=r&se=2026-09-01T15:00:00Z&st=2023-09-01T15:00:00Z&spr=https&sig=HFDNx%2BmZ960d7rYVH2HueaSy310JqOudA8cmdUhMJd4%3D';

    public static token$: Subject<string> = new Subject<string>();
    public static unauthorized$: Subject<boolean> = new Subject<boolean>();

    private static isFlaggedMode$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    public static get CONST_OTK_TOKEN(): string {
        return 'OTK_TOKEN';
    }

    public static get CONST_OTK_GESTIO_ANUNCIO_POPUP(): string {
        return 'OTK_GESTIO_ANUNCIO_POPUP';
    }

    public static get APP_SISTEMA(): string {
        return 'OTK';
    }

    public static get APP_VERSION(): string {
        const v = 'v4.215.7';
        return v;
    }

    public static get APP_VERSION_ENVIRONMENT(): string {
        let v = this.APP_VERSION;
        if (!environment.production) {
            if (environment.local) {
                v = `${v} (DEV-LOCAL)`;
            } else {
                v = `${v} (DEV)`;
            }
        }

        return v;
    }

    public static get APP_PRODUCTION_MODE(): boolean {
        return environment.production;
    }

    public static get APP_DEV_MODE(): boolean {
        return !environment.production;
    }

    public static get APP_LOCAL_MODE(): boolean {
        if (!environment.production) {
            return environment.local;
        }
        return false;
    }

    public static SetIsFlaggedMode(b: boolean): void {
        this.isFlaggedMode$.next(b);
    }
    public static get APP_FLAG_MODE(): boolean {
        return this.isFlaggedMode$.value;
    }

    public static get API_ENDPOINT_AUTH(): string {
        return environment.otkerpApiAuth;
    }

    public static get API_ENDPOINT_AUTH_WEB(): string {
        return environment.otkerpApiAuthWeb;
    }

    public static get API_ENDPOINT_CADASTROS(): string {
        return environment.otkerpApiGeneral;
    }

    public static get API_ENDPOINT_COMPRAS(): string {
        return environment.otkerpApiPurchase;
    }

    public static get API_ENDPOINT_ESTOQUE(): string {
        return environment.otkerpApiStock;
    }

    public static get API_ENDPOINT_VENDAS(): string {
        return environment.otkerpApiSales;
    }

    public static get API_ENDPOINT_FINANCEIRO(): string {
        return environment.otkerpApiFinancial;
    }

    public static get API_ENDPOINT_SISTEMA(): string {
        return environment.otkerpApiSystemApplication;
    }

    public static get API_ENDPOINT_RELATORIOS(): string {
        return environment.otkerpApiReports;
    }

    public static get URL_TEMPFILES_RELATORIOS(): string {
        let r = this.API_ENDPOINT_RELATORIOS;

        // HACK (RCO): tratamento qdo executado por Docker Compose
        if (r == '/reports-api') {
            let baseURL = window.location.origin;
            return baseURL + r + '/TempFiles';
        }

        r = r.replace(/\/api/g, '');
        return r + '/TempFiles';
    }

    public static URL_TOKEN_SAS(url: string): string {
        if (
            url.startsWith('https://otkwebstorage.blob.core.windows.net/dev-')
        ) {
            return `${url}${this.TOKEN_SAS_DEV}`;
        }

        if (url.startsWith('https://otkwebstorage.blob.core.windows.net/')) {
            return `${url}${this.TOKEN_SAS_PROD}`;
        }

        return url;
    }

    public static HTTP_AUTHORIZATION_HEADERS(
        headers: HttpHeaders
    ): HttpHeaders {
        let token = this.recuperarToken();

        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
            headers = headers.set('Pragma', 'no-cache');
            headers = headers.set('Cache-Control', 'no-cache');
        }

        return headers;
    }

    public static armazenarToken(token: string): void {
        this.limparToken();
        if (token.length > 0) {
            sessionStorage.setItem(AppSettings.CONST_OTK_TOKEN, token);
            this.token$.next(token);
        }
    }
    public static recuperarToken(): string {
        return sessionStorage.getItem(AppSettings.CONST_OTK_TOKEN);
    }
    public static limparToken(): void {
        this.token$.next(null);
        sessionStorage.removeItem(AppSettings.CONST_OTK_TOKEN);
        sessionStorage.removeItem(AppSettings.CONST_OTK_GESTIO_ANUNCIO_POPUP);
    }

    public static armazenarAppVersion(): void {
        localStorage.setItem(this.OTK_VERSION, this.APP_VERSION);
    }
    public static recuperarAppVersion(): string {
        return localStorage.getItem(this.OTK_VERSION);
    }
    public static recarregarAppVersion(): boolean {
        const version = this.recuperarAppVersion();
        if (version === null || version === undefined) {
            this.armazenarAppVersion();

            if (this.APP_DEV_MODE) {
                console.log(
                    '[DEV] AppVersion - undefined ->',
                    version,
                    this.APP_VERSION
                );
            }

            return this.recuperarAppVersion() == this.APP_VERSION;
        }

        if (version != this.APP_VERSION) {
            this.armazenarAppVersion();

            if (this.APP_DEV_MODE) {
                console.log(
                    '[DEV] AppVersion - diff ->',
                    version,
                    this.APP_VERSION
                );
            }

            return this.recuperarAppVersion() == this.APP_VERSION;
        }

        if (this.APP_DEV_MODE) {
            console.log(
                '[DEV] AppVersion - updated ->',
                version,
                this.APP_VERSION
            );
        }

        return false;
    }

    public static armazenarMenuOpen(open: boolean): void {
        localStorage.setItem(this.OTK_MENU_OPEN, open.toString());
    }
    public static recuperarMenuOpen(): boolean {
        let open = localStorage.getItem(this.OTK_MENU_OPEN);
        if (open === null || open === undefined) {
            this.armazenarMenuOpen(false);
            return false;
        }
        return open == 'true';
    }

    public static lembrarLogin(email: string): void {
        localStorage.setItem(this.OTK_REMEMBER_EMAIL, email);
        localStorage.setItem(this.OTK_REMEMBER_TOKEN, this.recuperarToken());
    }
    public static recuperarLogin(): string {
        let email = localStorage.getItem(this.OTK_REMEMBER_EMAIL);
        let token = localStorage.getItem(this.OTK_REMEMBER_TOKEN);
        if (email && token) {
            this.armazenarToken(token);
        }
        return email;
    }
    public static esquecerLogin(email: boolean = false): void {
        if (email) {
            localStorage.removeItem(this.OTK_REMEMBER_EMAIL);
        }
        localStorage.removeItem(this.OTK_REMEMBER_TOKEN);
    }

    public static exibirAvisoAtualizacoes(codusr: string): boolean {
        if (!codusr) {
            return false;
        }
        let version = localStorage.getItem(
            `${this.OTK_CLICK_VERSION}_${codusr}`
        );
        return this.APP_VERSION != version;
    }
    public static configurarAvisoAtualizacoes(codusr: string): void {
        if (!codusr) {
            return;
        }
        localStorage.setItem(
            `${this.OTK_CLICK_VERSION}_${codusr}`,
            this.APP_VERSION
        );
    }
}
