import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'gestio-cmp-content-area',
    templateUrl: './cmp-content-area.component.html',
    styleUrls: ['./cmp-content-area.component.scss'],
})
export class CmpContentAreaGestioComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
