export enum StateModeEnum {
    undefined = 0,

    insert = 1,
    edit = 2,
    browse = 4,
    selected = 5,
    modified = 6,
    removed = 7,
}

export enum UnidadeMedidaEnum {
    QTDE = 'QTDE',
    METRO = 'M',
    CENTIMETRO = 'CM',
}

export enum GridNameEnum {
    OTKWEB_AGENCIA = 'OTKWEB_AGENCIA_V0',
    OTKWEB_AJUSTE_ESTOQUE = 'OTKWEB_AJUSTE_ESTOQUE_V0',
    OTKWEB_ALMOXARIFADO = 'OTKWEB_ALMOXARIFADO_V0',
    OTKWEB_BANCO = 'OTKWEB_BANCO_V0',
    OTKWEB_BASE_FORNECEDORES = 'OTKWEB_BASE_FORNECEDORES_V0',
    OTKWEB_CAIXA_BANCOS = 'OTKWEB_CAIXA_BANCOS_V0',
    OTKWEB_CARTEIRA = 'OTKWEB_CARTEIRA_V0',
    OTKWEB_CATEGORIA_CLIENTE = 'OTKWEB_CATEGORIA_CLIENTE_V0',
    OTKWEB_CENTRO_DE_CUSTO = 'OTKWEB_CENTRO_DE_CUSTO_V0',
    OTKWEB_CENTRO_DE_CUSTO_GRUPO = 'OTKWEB_CENTRO_DE_CUSTO_GRUPO_V0',
    OTKWEB_CFOP = 'OTKWEB_CFOP_V0',
    OTKWEB_CLASSIFICACAO_AUXILIAR = 'OTKWEB_CLASSIFICACAO_AUXILIAR_V0',
    OTKWEB_CLASSIFICACAO_AUXILIAR_ESTOQUE = 'OTKWEB_CLASSIFICACAO_AUXILIAR_ESTOQUE_V0',
    OTKWEB_CLIENTE_ARQUIVOS_ANEXADOS = 'OTKWEB_CLIENTE_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_CODIGO_DE_SERVICO = 'OTKWEB_CODIGO_DE_SERVICO_V0',
    OTKWEB_COLABORADOR_USUARIO = 'OTKWEB_COLABORADOR_USUARIO_V0',
    OTKWEB_COLABORADOR_REQUISITANTE = 'OTKWEB_COLABORADOR_REQUISITANTE_V0',
    OTKWEB_COLABORADOR_GRUPO = 'OTKWEB_COLABORADOR_GRUPO_V0',
    OTKWEB_CONDICAO_DE_PAGAMENTO_PARCELAS = 'OTKWEB_CONDICAO_DE_PAGAMENTO_PARCELAS_V0',
    OTKWEB_CONTA_CORRENTE = 'OTKWEB_CONTA_CORRENTE_V0',
    OTKWEB_CONTAS_PAGAR = 'OTKWEB_CONTAS_PAGAR_V0',
    OTKWEB_CONTAS_PAGAR_BX = 'OTKWEB_CONTAS_PAGAR_BX_V0',
    OTKWEB_CONTAS_PAGAR_RATEIOS = 'OTKWEB_CONTAS_PAGAR_RATEIOS_V0',
    OTKWEB_CONTAS_PAGAR_PESQUISAR = 'OTKWEB_CONTAS_PAGAR_PESQUISAR_V0',
    OTKWEB_CONTAS_RECEBER = 'OTKWEB_CONTAS_RECEBER_V0',
    OTKWEB_CONTAS_RECEBER_RATEIOS = 'OTKWEB_CONTAS_RECEBER_RATEIOS_V0',
    OTKWEB_CONTAS_RECEBER_BX = 'OTKWEB_CONTAS_RECEBER_BX_V0',
    OTKWEB_CONTAS_RECEBER_PREJUIZOS = 'OTKWEB_CONTAS_RECEBER_PREJUIZOS_V0',
    OTKWEB_CONTAS_RECEBER_PESQUISAR = 'OTKWEB_CONTAS_RECEBER_PESQUISAR_V0',
    OTKWEB_CONTRATO_DE_FORNECIMENTO = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_V0',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_TIPO = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_TPO_V0',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_ATIVOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_ATIVOS_V0',
    OTKWEB_CONTRATO_DE_FORNECIMENTO_INATIVOS = 'OTKWEB_CONTRATO_DE_FORNECIMENTO_INATIVOS_V0',
    OTKWEB_COTACAO_SELECAO_FORNECEDORES = 'OTKWEB_COTACAO_SELECAO_FORNECEDORES_V0',
    OTKWEB_COTACOES_FORNECEDOR = 'OTKWEB_COTACOES_FORNECEDOR_V0',
    OTKWEB_COTACOES_ITENS_GRID = 'OTKWEB_COTACOES_ITENS_GRID_V0',
    OTKWEB_CR_CP_ARQUIVOS_ANEXADOS = 'OTKWEB_CR_CP_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS = 'OTKWEB_PREVISAO_CR_CP_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_CRM_CLIENTES = 'OTKWEB_CRM_CLIENTES_V0',
    OTKWEB_CRM_CLIENTES_CONTAS_RECEBER = 'OTKWEB_CRM_CLIENTES_CONTAS_RECEBER_V0',
    OTKWEB_CRM_CLIENTES_CONTATO = 'OTKWEB_CRM_CLIENTES_CONTATO_V0',
    OTKWEB_CRM_CLIENTES_PESQUISAR = 'OTKWEB_CRM_CLIENTES_PESQUISAR_V0',
    OTKWEB_CRM_TIPO_CLIENTES = 'OTKWEB_CRM_TIPO_CLIENTES_V0',
    OTKWEB_CRM_SITUACAO_CLIENTES = 'OTKWEB_CRM_SITUACAO_CLIENTES_V0',
    OTKWEB_CUSTO_ABC = 'OTKWEB_CUSTO_ABC_V0',
    OTKWEB_DUPLICATA_COMPRAS = 'OTKWEB_DUPLICATA_COMPRAS_V0',
    OTKWEB_DUPLICATA_VENDAS = 'OTKWEB_DUPLICATA_VENDAS_V0',
    OTKWEB_DUPLICATA_HISTORICO_CP = 'OTKWEB_DUPLICATA_HISTORICO_CP_V0',
    OTKWEB_DUPLICATA_HISTORICO_CR = 'OTKWEB_DUPLICATA_HISTORICO_CR_V0',
    OTKWEB_ENDERECO_DE_ENTREGA = 'OTKWEB_ENDERECO_DE_ENTREGA_V0',
    OTKWEB_ENTRADA_DE_ESTOQUE = 'OTKWEB_ENTRADA_DE_ESTOQUE_V0',
    OTKWEB_ENTRADA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_ENTRADA_DE_ESTOQUE_PESQUISAR_V0',
    OTKWEB_ENTRADA_LOTE_ABERTAS = 'OTKWEB_ENTRADA_LOTE_ABERTAS_V0',
    OTKWEB_ENTRADA_LOTE_ENCERRADAS = 'OTKWEB_ENTRADA_LOTE_ABERTAS_V0',
    OTKWEB_ENTRADA_LOTE_ITENS = 'OTKWEB_ENTRADA_LOTE_ITENS_V0',
    OTKWEB_EXPEDICAO_CANCELADOS = 'OTKWEB_EXPEDICAO_CANCELADOS_V0',
    OTKWEB_EXPEDICAO_DE_ESTOQUE_PESQUISAR = 'OTKWEB_EXPEDICAO_DE_ESTOQUE_PESQUISAR_V0',
    OTKWEB_EXPEDICAO_PENDENTES = 'OTKWEB_EXPEDICAO_PENDENTES_V0',
    OTKWEB_EXPEDICAO_EM_PROCESSAMENTO = 'OTKWEB_EXPEDICAO_EM_PROCESSAMENTO_V0',
    OTKWEB_EXPEDICAO_PROCESSADOS = 'OTKWEB_EXPEDICAO_PROCESSADOS_V0',
    OTKWEB_EXPORTACAO_DE_RELATORIO = 'OTKWEB_EXPORTACAO_DE_RELATORIO_V0',

    OTKWEB_EXPEDICAO_POR_REQUISICAO = 'OTKWEB_EXPEDICAO_POR_REQUISICAO_V0',
    OTKWEB_EXPEDICAO_POR_REQUISICAO_DETALHE = 'OTKWEB_EXPEDICAO_POR_REQUISICAO_DETALHE_V0',

    OTKWEB_FATURA_ABERTAS = 'OTKWEB_FATURA_ABERTAS_V0',
    OTKWEB_FATURA_CANCELADAS = 'OTKWEB_FATURA_CANCELADAS_V0',
    OTKWEB_FATURA_PROCESSADAS = 'OTKWEB_FATURA_PROCESSADAS_V0',
    OTKWEB_FATURA_SERVICOS = 'OTKWEB_FATURA_SERVICOS_V0',

    OTKWEB_FILIAL = 'OTKWEB_FILIAL_V0',
    OTKWEB_FINANCEIRO_CAIXA_BANCOS = 'OTKWEB_FINANCEIRO_CAIXA_BANCOS_V0',
    OTKWEB_FINANCEIRO_CAIXA_CP = 'OTKWEB_FINANCEIRO_CAIXA_CP_V0',
    OTKWEB_FINANCEIRO_CAIXA_CR = 'OTKWEB_FINANCEIRO_CAIXA_CR_V0',
    OTKWEB_FINANCEIRO_CAIXA_DESDOBRAMENTOS = 'OTKWEB_FINANCEIRO_CAIXA_DESDOBRAMENTOS_V0',
    OTKWEB_CONDICAO_DE_PAGAMENTO = 'OTKWEB_CONDICAO_DE_PAGAMENTO_V0',
    OTKWEB_MAPEAMENTO_DE_RATEIO = 'OTKWEB_MAPEAMENTO_DE_RATEIO_V0',
    OTKWEB_FORNECEDOR = 'OTKWEB_FORNECEDOR_V0',
    OTKWEB_FORNECEDOR_ARQUIVOS_ANEXADOS = 'OTKWEB_FORNECEDOR_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_FORNECEDOR_CONTATO = 'OTKWEB_FORNECEDOR_CONTATO_V0',
    OTKWEB_FORNECEDOR_COTACOES = 'OTKWEB_FORNECEDOR_COTACOES_V0',
    OTKWEB_FORNECEDOR_CP = 'OTKWEB_FORNECEDOR_CP_V0',
    OTKWEB_FORNECEDOR_PEDIDO_DE_COMPRA = 'OTKWEB_FORNECEDOR_PEDIDO_DE_COMPRA_V0',
    OTKWEB_FORNECEDOR_PESQUISAR = 'OTKWEB_FORNECEDOR_PESQUISAR_V0',
    OTKWEB_SEGMENTO = 'OTKWEB_SEGMENTO_V0',
    OTKWEB_RAMO_DE_ATIVIDADE = 'OTKWEB_RAMO_DE_ATIVIDADE_V0',
    OTKWEB_FORNECEDOR_TIPO = 'OTKWEB_FORNECEDOR_TIPO_V0',
    OTKWEB_GRUPO_LANCAMENTO = 'OTKWEB_GRUPO_LANCAMENTO_V0',
    OTKWEB_ITEM_LANCAMENTO = 'OTKWEB_ITEM_LANCAMENTO_V0',
    OTKWEB_MAPEAMENTO_PRODUTO = 'OTKWEB_MAPEAMENTO_PRODUTO_V0',
    OTKWEB_MOTIVO_DA_COMPRA = 'OTKWEB_MOTIVO_DA_COMPRA_V0',
    OTKWEB_MOTIVO_REQUISICAO = 'OTKWEB_MOTIVO_REQUISICAO_V0',
    OTKWEB_NCM = 'OTKWEB_NCM_V0',
    OTKWEB_NIVEL_DE_APROVACAO = 'OTKWEB_NIVEL_DE_APROVACAO_V0',
    OTKWEB_NIVEL_DE_APROVACAO_ESTOQUE = 'OTKWEB_NIVEL_DE_APROVACAO_ESTOQUE_V0',
    OTKWEB_NOTA_FISCAL_PRODUTO_CANCELADAS = 'OTKWEB_NOTA_FISCAL_PRODUTO_CANCELADAS_V0',
    OTKWEB_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO = 'OTKWEB_NOTA_FISCAL_PRODUTO_CARTAS_CORRECAO_V0',
    OTKWEB_NOTA_FISCAL_PRODUTO_EMITIDAS = 'OTKWEB_NOTA_FISCAL_PRODUTO_EMITIDAS_V0',
    OTKWEB_NOTA_FISCAL_PRODUTO_ITENS = 'OTKWEB_NOTA_FISCAL_PRODUTO_ITENS_V0',
    OTKWEB_NOTA_FISCAL_PRODUTO_SERVICOS = 'OTKWEB_NOTA_FISCAL_PRODUTO_SERVICOS_V0',
    OTKWEB_NOTA_FISCAL_PRODUTO_PENDENTES = 'OTKWEB_NOTA_FISCAL_PRODUTO_PENDENTES_V0',
    OTKWEB_NOTA_FISCAL_SERVICO_CANCELADAS = 'OTKWEB_NOTA_FISCAL_SERVICO_CANCELADAS_V0',
    OTKWEB_NOTA_FISCAL_SERVICO_EMITIDAS = 'OTKWEB_NOTA_FISCAL_SERVICO_EMITIDAS_V0',
    OTKWEB_NOTA_FISCAL_SERVICO_PENDENTES = 'OTKWEB_NOTA_FISCAL_SERVICO_PENDENTES_V0',
    OTKWEB_ORDEM_DE_COMPRA_ABERTAS = 'OTKWEB_ORDEM_DE_COMPRA_ABERTAS_V0',
    OTKWEB_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS = 'OTKWEB_ORDEM_DE_COMPRA_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_ORDEM_DE_COMPRA_COTACOES = 'OTKWEB_ORDEM_DE_COMPRA_COTACOES_V0',
    OTKWEB_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO = 'OTKWEB_ORDEM_DE_COMPRA_COTACOES_HISTORICO_PRODUTO_V0',
    OTKWEB_ORDEM_DE_COMPRA_ENCERRADAS = 'OTKWEB_ORDEM_DE_COMPRA_ENCERRADAS_V0',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ABERTAS = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ABERTAS__V0  V1',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ENCERRADAS = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ENCERRADAS_V0',
    OTKWEB_ORDEM_DE_COMPRA_LOTE_ITENS = 'OTKWEB_ORDEM_DE_COMPRA_LOTE_ITENS_V0',
    OTKWEB_ORDEM_DE_COMPRA_PENDENTE_APROVACAO = 'OTKWEB_ORDEM_DE_COMPRA_PENDENTE_APROVACAO_V0',
    OTKWEB_ORDEM_DE_COMPRA_PESQUISAR = 'OTKWEB_ORDEM_DE_COMPRA_PESQUISAR_V0',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_V0',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_CONSOLIDADA_V0',
    OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_RATEIO = 'OTKWEB_ORDEM_DE_COMPRA_PRODUTOS_RATEIO_V0',
    OTKWEB_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES = 'OTKWEB_ORDEM_DE_COMPRA_SELECIONAR_FORNECEDORES_V0',
    OTKWEB_ORDEM_PRODUTO = 'OTKWEB_ORDEM_PRODUTO_V0',
    OTKWEB_ORDEM_SERVICOS_ABERTAS = 'OTKWEB_ORDEM_SERVICOS_ABERTAS_V0',
    OTKWEB_ORDEM_SERVICOS_CANCELADAS = 'OTKWEB_ORDEM_SERVICOS_CANCELADAS_V0',
    OTKWEB_ORDEM_SERVICOS_PROCESSADAS = 'OTKWEB_ORDEM_SERVICOS_PROCESSADAS_V0',
    OTKWEB_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL = 'OTKWEB_ORDEM_SERVICOS_PROCESSAMENTO_PARCIAL_V0',
    OTKWEB_PAIS = 'OTKWEB_PAIS_V0',
    OTKWEB_PARCELAS_PEDIDO_DE_COMPRA = 'OTKWEB_PARCELAS_PEDIDO_DE_COMPRA_V0',
    OTKWEB_PEDIDO_DE_VENDA_ABERTAS = 'OTKWEB_PEDIDO_DE_VENDA_ABERTAS_V0',
    OTKWEB_PEDIDO_DE_VENDA_CANCELADAS = 'OTKWEB_PEDIDO_DE_VENDA_CANCELADAS_V0',
    OTKWEB_PEDIDO_DE_VENDA_PROCESSADAS = 'OTKWEB_PEDIDO_DE_VENDA_PROCESSADAS_V0',
    OTKWEB_PEDIDO_DE_VENDA_PRODUTO_ITENS = 'OTKWEB_PEDIDO_DE_VENDA_PRODUTO_ITENS_V0',
    OTKWEB_PEDIDO_DE_COMPRA_ABERTAS = 'OTKWEB_PEDIDO_DE_COMPRA_ABERTAS_V0',
    OTKWEB_PEDIDO_DE_COMPRA_ENCERRADAS = 'OTKWEB_PEDIDO_DE_COMPRA_ENCERRADAS_V0',
    OTKWEB_PEDIDO_DE_COMPRA_ITENS = 'OTKWEB_PEDIDO_DE_COMPRA_ITENS_V0',
    OTKWEB_PEDIDO_DE_COMPRA_PESQUISAR = 'OTKWEB_PEDIDO_DE_COMPRA_PESQUISAR_V0',
    OTKWEB_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL = 'OTKWEB_PEDIDO_DE_COMPRA_PROCESSAMENTO_PARCIAL_V0',
    OTKWEB_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO = 'OTKWEB_PEDIDO_DE_COMPRA_PENDENTE_APROVACAO_V0',
    OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS = 'OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL = 'OTKWEB_PEDIDOS_ARQUIVOS_ANEXADOS_NOTA_FISCAL_V0',
    OTKWEB_PLANO_DE_CONTAS = 'OTKWEB_PLANO_DE_CONTAS_V0',
    OTKWEB_PLANEJAMENTO_ORCAMENTARIO = 'OTKWEB_PLANEJAMENTO_ORCAMENTARIO_V0',
    OTKWEB_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS = 'OTKWEB_PLANEJAMENTO_ORCAMENTARIO_PEDIDOS_V0',
    OTKWEB_PREVISAO_CONTAS_PAGAR = 'OTKWEB_PREVISAO_CONTAS_PAGAR_V0',
    OTKWEB_PREVISAO_CONTAS_PAGAR_RATEIOS = 'OTKWEB_PREVISAO_CONTAS_PAGAR_RATEIOS_V0',
    OTKWEB_PREVISAO_CONTAS_RECEBER = 'OTKWEB_PREVISAO_CONTAS_RECEBER_V0',
    OTKWEB_PREVISAO_CONTAS_RECEBER_RATEIOS = 'OTKWEB_PREVISAO_CONTAS_RECEBER_RATEIOS_V0',
    OTKWEB_PREVISAO_FATURAMENTO_SERVICO = 'OTKWEB_PREVISAO_FATURAMENTO_SERVICO_V0',
    OTKWEB_PREVISAO_FATURAMENTO_SERVICO_ITENS = 'OTKWEB_PREVISAO_FATURAMENTO_SERVICO_ITENS_V0',
    OTKWEB_PREVISAO_FATURAMENTO_FATURA = 'OTKWEB_PREVISAO_FATURAMENTO_FATURA_V0',
    OTKWEB_PREVISAO_FATURAMENTO_FATURA_SERVICOS = 'OTKWEB_PREVISAO_FATURAMENTO_FATURA_SERVICOS_V0',
    OTKWEB_PREVISAO_HISTORICO_CP = 'OTKWEB_PREVISAO_HISTORICO_CP_V0',
    OTKWEB_PREVISAO_HISTORICO_CR = 'OTKWEB_PREVISAO_HISTORICO_CR_V0',
    OTKWEB_PRODUTO = 'OTKWEB_PRODUTO_V0',
    OTKWEB_PRODUTO_ARQUIVOS_ANEXADOS = 'OTKWEB_PRODUTO_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_PRODUTO_CATEGORIA = 'OTKWEB_PRODUTO_CATEGORIA_V0',
    OTKWEB_PRODUTO_ESTOQUE = 'OTKWEB_PRODUTO_ESTOQUE_V0',
    OTKWEB_PRODUTO_ESTOQUE_ENDERECAMENTOS = 'OTKWEB_PRODUTO_ESTOQUE_ENDERECAMENTOS_V0',
    OTKWEB_PRODUTO_GRUPO = 'OTKWEB_PRODUTO_GRUPO_V0',
    OTKWEB_PRODUTO_MOVIMENTACAO = 'OTKWEB_PRODUTO_MOVIMENTACAO_V0',
    OTKWEB_PRODUTO_PEDIDOS_COMPRAS = 'OTKWEB_PRODUTO_PEDIDOS_COMPRAS_V0',
    OTKWEB_PRODUTO_PESQUISAR = 'OTKWEB_PRODUTO_PESQUISAR_V0',
    OTKWEB_PRODUTO_SERIES_LOTE = 'OTKWEB_PRODUTO_SERIES_LOTE_V0',
    OTKWEB_PRODUTO_SUGESTAO_COMPRAS = 'OTKWEB_PRODUTO_SUGESTAO_COMPRAS_V0',

    OTKWEB_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA = 'OTKWEB_PRODUTO_CONVERSAO_DE_UNIDADE_MEDIDA_V0',

    OTKWEB_PROJETO = 'OTKWEB_PROJETO_V0',
    OTKWEB_PROJETO_ARQUIVOS_ANEXADOS = 'OTKWEB_PROJETO_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_PROJETO_MOVIMENTACAO = 'OTKWEB_PROJETO_MOVIMENTACAO_V0',
    OTKWEB_PROJETO_PEDIDO_DE_COMPRA = 'OTKWEB_PROJETO_PEDIDO_DE_COMPRA_V0',
    OTKWEB_PROJETO_CONTAS_PAGAR = 'OTKWEB_PROJETO_CONTAS_PAGAR_V0',
    OTKWEB_PROJETO_CONTAS_RECEBER = 'OTKWEB_PROJETO_CONTAS_RECEBER_V0',
    OTKWEB_RECEBIMENTO_DE_COMPRA_PESQUISAR = 'OTKWEB_RECEBIMENTO_DE_COMPRA_PESQUISAR_V0',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_CANCELADOS = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_CANCELADOS_V0',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_PENDENTES = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_PENDENTES_V0',
    OTKWEB_RECEBIMENTO_DE_COMPRAS_PROCESSADOS = 'OTKWEB_RECEBIMENTO_DE_COMPRAS_PROCESSADOS_V0',
    OTKWEB_RECEBIMENTOS_POR_PEDIDO = 'OTKWEB_RECEBIMENTOS_POR_PEDIDO_V0',
    OTKWEB_RECEBIMENTOS_POR_PEDIDO_DETALHE = 'OTKWEB_RECEBIMENTOS_POR_PEDIDO_DETALHE_V0',
    OTKWEB_REGRA_DE_APROVACAO = 'OTKWEB_REGRA_DE_APROVACAO_V0',
    OTKWEB_REGRA_DE_APROVACAO_OC = 'OTKWEB_REGRA_DE_APROVACAO_OC_V0',
    OTKWEB_REGRA_DE_APROVACAO_OL = 'OTKWEB_REGRA_DE_APROVACAO_OL_V0',
    OTKWEB_REGRA_DE_APROVACAO_PC = 'OTKWEB_REGRA_DE_APROVACAO_PC_V0',
    OTKWEB_REGRA_DE_APROVACAO_RC = 'OTKWEB_REGRA_DE_APROVACAO_RC_V0',
    OTKWEB_REQUISICAO_DE_COMPRAS_ABERTAS = 'OTKWEB_REQUISICAO_ABERTAS_DE_COMPRAS_V0',
    OTKWEB_REQUISICAO_DE_COMPRAS_ITENS = 'OTKWEB_REQUISICAO_DE_COMPRAS_ITENS_V0',
    OTKWEB_REQUISICAO_DE_COMPRAS_ENCERRADAS = 'OTKWEB_REQUISICAO_ENCERRADAS_DE_COMPRAS_V0',
    OTKWEB_REQUISICAO_DE_COMPRAS_PESQUISAR = 'OTKWEB_REQUISICAO_DE_COMPRAS_PESQUISAR_V0',
    OTKWEB_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS = 'OTKWEB_REQUISICAO_DE_COMPRAS_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO = 'OTKWEB_REQUISICAO_DE_COMPRAS_PENDENTE_APROVACAO_V0',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ARQUIVOS_ANEXADOS_V0',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ABERTAS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ABERTAS_V0',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ENCERRADAS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ENCERRADAS_V0',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS_V0',
    OTKWEB_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS = 'OTKWEB_REQUISICAO_DE_ESTOQUE_DETALHES_ITENS_V0',
    OTKWEB_REQUISICAO_DE_ESTOQUE_PESQUISAR = 'OTKWEB_REQUISICAO_DE_ESTOQUE_PESQUISAR_V0',
    OTKWEB_REQUISICAO_PRODUTO = 'OTKWEB_REQUISICAO_PRODUTO_V0',
    OTKWEB_SAIDA_DE_ESTOQUE = 'OTKWEB_SAIDA_DE_ESTOQUE_V0',
    OTKWEB_SAIDA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_SAIDA_DE_ESTOQUE_PESQUISAR_V0',
    OTKWEB_SALDOS_EM_ESTOQUE = 'OTKWEB_SALDOS_EM_ESTOQUE_V0',
    OTKWEB_SECAO = 'OTKWEB_SECAO_V0',
    OTKWEB_SUGESTAO_DE_COMPRAS = 'OTKWEB_SUGESTAO_DE_COMPRAS_V0',
    OTKWEB_TIPO_DE_COMPRA = 'OTKWEB_TIPO_DE_COMPRA_V0',
    OTKWEB_TIPO_DE_DOCUMENTO = 'OTKWEB_TIPO_DE_DOCUMENTO_V0',
    OTKWEB_TIPO_DE_PAGAMENTO = 'OTKWEB_TIPO_DE_PAGAMENTO_V0',
    OTKWEB_TIPO_PRODUTO = 'OTKWEB_TIPO_PRODUTO_V0',
    OTKWEB_TIPO_REQUISICAO = 'OTKWEB_TIPO_REQUISICAO_V0',
    OTKWEB_TRANSFERENCIA_EM_LOTE_ESTOQUE = 'OTKWEB_TRANSFERENCIA_EM_LOTE_V0',
    OTKWEB_TRANSFERENCIAS = 'OTKWEB_TRANSFERENCIAS_V0',
    OTKWEB_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR = 'OTKWEB_TRANSFERENCIA_DE_ESTOQUE_PESQUISAR_V0',
    OTKWEB_UNIDADE_MEDIDA = 'OTKWEB_UNIDADE_MEDIDA_V0',
    OTKWEB_VENDAS_FATURA_PESQUISAR = 'OTKWEB_VENDAS_FATURA_PESQUISAR_V0',
    OTKWEB_VENDAS_NFE_PESQUISAR = 'OTKWEB_VENDAS_NFE_PESQUISAR_V0',
    OTKWEB_VENDAS_NFSE_PESQUISAR = 'OTKWEB_VENDAS_NFSE_PESQUISAR_V0',
    OTKWEB_VENDAS_ORDEM_PESQUISAR = 'OTKWEB_VENDAS_ORDEM_PESQUISAR_V0',
    OTKWEB_VENDAS_PEDIDO_PESQUISAR = 'OTKWEB_VENDAS_PEDIDO_PESQUISAR_V0',
    OTKWEB_VENDAS_ORDEM_SERVICOS = 'OTKWEB_VENDAS_ORDEM_SERVICOS_V0',
    OTKWEB_VENDAS_PIS = 'OTKWEB_VENDAS_PIS_V0',
    OTKWEB_VENDAS_VENDEDOR = 'OTKWEB_VENDAS_VENDEDOR_V0',
    OTKWEB_COFINS = 'OTKWEB_COFINS_V0',
    OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID = 'OTKWEB_REQUISICAO_DE_ESTOQUE_ITENS_DATAGRID_V0',
    OTKWEB_VENDAS_DISCRIMINACAO_SERVICOS = 'OTKWEB_VENDAS_DISCRIMINACAO_SERVICOS_V0',
    OTKWEB_VENDAS_NOTA_FISCAL_PRODUTOS = 'OTKWEB_VENDAS_NOTA_FISCAL_PRODUTOS_V0',
    OTKWEB_VENDAS_NOTA_FISCAL_SERVICOS = 'OTKWEB_VENDAS_NOTA_FISCAL_SERVICO_V0',
    OTKWEB_ORDEM_COMPOSICAO_FINANCEIRA = 'OTKWEB_ORDEM_COMPOSICAO_FINANCEIRA_V0',
    OTKWEB_COTACAO_COMPARACAO = 'OTKWEB_COTACAO_COMPARACAO_V0',
    OTKWEB_MAPA_DE_COTACOES = 'OTKWEB_MAPA_DE_COTACOES_V0',
    OTKWEB_PEDIDO_DE_COMPRA_RATEIOS = 'OTKWEB_PEDIDO_DE_COMPRA_RATEIOS_V0',
    OTKWEB_CRM_CLIENTES_ORDEM_DE_SERVICO = 'OTKWEB_CRM_CLIENTES_ORDEM_DE_SERVICO_V0',
    OTKWEB_CRM_CLIENTES_PEDIDO_DE_VENDA = 'OTKWEB_CRM_CLIENTES_PEDIDO_DE_VENDA_V0',
    OTKWEB_GRUPO_DE_CLIENTE = 'OTKWEB_GRUPO_DE_CLIENTE_V0',
    OTKWEB_CRM_CLIENTES_FATURAS = 'OTKWEB_CRM_CLIENTES_FATURAS_V0',
    OTKWEB_FINANCEIRO_EXTRATO = 'OTKWEB_FINANCEIRO_EXTRATO_V0',
    OTKWEB_FINANCEIRO_EXTRATO_LANCAMENTOS = 'OTKWEB_FINANCEIRO_EXTRATO_LANCAMENTOS_V0',
    OTKWEB_FINANCEIRO_EXTRATO_CRCP = 'OTKWEB_FINANCEIRO_EXTRATO_CRCP_V0',
    OTKWEB_FINANCEIRO_PAGAMENTOS = 'OTKWEB_FINANCEIRO_PAGAMENTOS_V0',
    OTKWEB_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA = 'OTKWEB_FINANCEIRO_PAGAMENTOS_GERAR_REMESSA_V0',
    OTKWEB_PRODUTO_PREVISAO_SALDO = 'OTKWEB_PRODUTO_PREVISAO_SALDO_V0',
    OTKWEB_PRODUTO_MARGEM_LUCRO = 'OTKWEB_PRODUTO_MARGEM_LUCRO_V0',
    OTKWEB_CONFIGURACAO_VENDAS = 'OTKWEB_CONFIGURACAO_VENDAS_V0',
    OTKWEB_MAPEAMENTO_PADRAO_PRODUTOS = 'OTKWEB_MAPEAMENTO_PADRAO_PRODUTOS_V0',
    OTKWEB_BRADESCO_QUESTIONARIO = 'OTKWEB_BRADESCO_QUESTIONARIO_V0',
    OTKWEB_TABELA_IBPT = 'OTKWEB_TABELA_IBPT_V0',
    OTKWEB_NOTA_FISCAL_PRODUTO_INUTILIZACAO = 'OTKWEB_NOTA_FISCAL_PRODUTO_INUTILIZACAO_V0',
    OTKWEB_ORCAMENTOS_ABERTOS = 'OTKWEB_ORCAMENTOS_ABERTOS_V0',
    OTKWEB_ORCAMENTOS_CANCELADOS = 'OTKWEB_ORCAMENTOS_CANCELADOS_V0',
    OTKWEB_ORCAMENTOS_PROCESSADOS = 'OTKWEB_ORCAMENTOS_PROCESSADOS_V0',
    OTKWEB_ORCAMENTOS_PRODUTOS = 'OTKWEB_ORCAMENTOS_PRODUTOS_V0',
    OTKWEB_NOTA_RECEBIDAS = 'OTKWEB_NOTA_RECEBIDAS_V0',
    OTKWEB_NOTA_RECEBIDAS_PRODUTOS = 'OTKWEB_NOTA_RECEBIDAS_PRODUTOS_V0',

    OTKWEB_INVENTARIO_CADASTROS = 'OTKWEB_INVENTARIO_CADASTROS_V0',
    OTKWEB_INVENTARIO_ITENS = 'OTKWEB_INVENTARIO_ITENS_V0',

    OTKWEB_CNAB_REMESSA_PENDENTE = 'OTKWEB_CNAB_REMESSA_PENDENTE_V0',
    OTKWEB_CNAB_REMESSA_GERADA = 'OTKWEB_CNAB_REMESSA_GERADA_V0',
    OTKWEB_CNAB_RETORNO = 'OTKWEB_CNAB_RETORNO_V0',

    OTKWEB_NOTA_DESTINADAS = 'OTKWEB_NOTA_DESTINADAS_V0',
    OTKWEB_NOTA_DESTINADAS_PRODUTOS = 'OTKWEB_NOTA_DESTINADAS_PRODUTOS_V0',
}

export enum TutorialEnum {
    FORNECEDOR = 'https://ajuda.otk.com.br/kb/pt-br/article/183783/fornecedores-passo-a-passo-como-cadastrar?ticketId=&q=#h_4f8e9b46-4681-4b33-8d85-b3f4019a5a44',
    FORNECEDOR_TIPO = 'https://ajuda.otk.com.br/kb/pt-br/article/489734/fornecedores?ticketId=&ticketId=&q=&q=&menuId=51166-0-489734',
    FORNECEDOR_CONTRATO = 'https://ajuda.otk.com.br/kb/pt-br/article/489772/fornecedores-como-cadastrar-contratos-de-fornecimento?ticketId=&ticketId=&q=&q=&menuId=51167-0-489772',
    FORNECEDOR_CONTRATO_TIPO = 'https://ajuda.otk.com.br/kb/pt-br/article/489775/fornecedores-como-cadastrar-tipos-de-contrato-de-fornecimento?ticketId=&q=',
    CLIENTE = 'https://ajuda.otk.com.br/kb/pt-br/article/183781/clientes-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20323-0-183781#h_deec7113-c553-4de7-b816-cdcc0cf03519',
    CLIENTE_GRUPO = 'https://ajuda.otk.com.br/kb/pt-br/article/183781/clientes-passo-a-passo-como-cadastrar#h_1e50033b-e24a-4da3-882b-a697a0dce3be',
    CLIENTE_CATEGORIA = 'https://ajuda.otk.com.br/kb/pt-br/article/183781/clientes-passo-a-passo-como-cadastrar#h_454f40fd-4750-44db-b40b-61381cdfad7f',
    CLIENTE_TIPO = 'https://ajuda.otk.com.br/kb/pt-br/article/183781/clientes-passo-a-passo-como-cadastrar#h_1e50033b-e24a-4da3-882b-a697a0dce3be',
    CLIENTE_SITUACAO = 'https://ajuda.otk.com.br/kb/pt-br/article/183781/clientes-passo-a-passo-como-cadastrar#h_1e50033b-e24a-4da3-882b-a697a0dce3be',
    CONDICAO_DE_PAGAMENTO = 'https://ajuda.otk.com.br/kb/',
    FORMA_DE_PAGAMENTO = 'https://ajuda.otk.com.br/kb/pt-br/article/183801/formas-de-pagamento-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20330-0-183801',
    CENTRO_DE_CUSTO = 'https://ajuda.otk.com.br/kb/pt-br/article/183765/centros-de-custo-e-projetos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20321-0-183765#h_fff79fbd-0278-49f4-88b9-3caa34ecc7dc',
    GRUPO_DE_CENTRO_DE_CUSTO = 'https://ajuda.otk.com.br/kb/pt-br/article/183765/centros-de-custo-e-projetos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20321-0-183765#h_bd6fe4a5-683d-4e75-9d15-8142836838d9',
    CLASSIFICACAO_AUXILIAR = 'https://ajuda.otk.com.br/kb/',
    MAPEAMENTO_DE_RATEIO = 'https://ajuda.otk.com.br/kb/',
    CATEGORIA = 'https://ajuda.otk.com.br/kb/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar#h_ed891c45-993f-4cea-9a25-9543ee9d4a10',
    PRODUTO = 'https://ajuda.otk.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785',
    PRODUTO_GRUPO = 'https://ajuda.otk.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_1e50033b-e24a-4da3-882b-a697a0dce3be',
    PRODUTO_CATEGORIA = 'https://ajuda.otk.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_95c15283-dd8a-4af7-abf2-c48f52fba471',
    PRODUTO_TIPO = 'https://ajuda.otk.com.br/kb/pt-br/article/183785/produtos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20325-0-183785#h_cf54003b-70e2-4218-b7bd-21d9cf448ebd',
    PROJETO = 'https://ajuda.otk.com.br/kb/pt-br/article/183765/centros-de-custo-e-projetos-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20321-0-183765#h_e1465a2e-f0f5-449b-9140-12998fabc15a',
    ENDERECOS_ADICIONAIS = 'https://ajuda.otk.com.br/kb/pt-br/article/183804/endereco-de-entrega-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20331-0-183804',
    REQUISICAO_DE_COMPRA = 'https://ajuda.otk.com.br/kb/pt-br/article/183813/requisicoes-de-compra-passo-a-passo-como-fazer?ticketId=&q=',
    ORDEM_DE_COMPRA = 'https://ajuda.otk.com.br/kb/pt-br/article/183819/ordens-de-compra-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20333-0-183819',
    PEDIDO_DE_COMPRA = 'https://ajuda.otk.com.br/kb/pt-br/article/183840/pedido-de-compra-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20337-0-183840',
    COLABORADOR = 'https://ajuda.otk.com.br/kb/pt-br/article/183772/colaboradores-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20322-0-183772',
    COLABORADOR_GRUPO = 'https://ajuda.otk.com.br/kb/pt-br/article/183772/colaboradores-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20322-0-183772#h_29480f3b-8d6a-41a3-b627-d1e40fcf6f30',
    FILIAL = 'https://ajuda.otk.com.br/kb/pt-br/article/183759/filiais-passo-a-passo-como-cadastrar',
    ORDEM_DE_SERVICO = 'https://ajuda.otk.com.br/kb/pt-br/article/183928/ordens-de-servico-passo-a-passo-como-fazer?ticketId=&q=',
    AGENCIA = 'https://ajuda.otk.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_43959041-d1c8-4b4c-9b3e-f009da77386d',
    CARTEIRA = 'https://ajuda.otk.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_9ce08242-eeae-46fe-9489-95a1eb3b224f',
    CONTA_CORRENTE = 'https://ajuda.otk.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_ba7f7cc2-6739-446d-a746-08f6d76e3def',
    CUSTO_ABC = 'https://ajuda.otk.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_3ec63e27-f23d-4dd6-8125-a5d4cfeecaf0',
    TIPO_DE_DOCUMENTO = 'https://ajuda.otk.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_bbf1fc85-a7a9-4b32-9b0a-d668c1d5a028',
    FINANCEIRO_CAIXA = 'https://ajuda.otk.com.br/kb/pt-br/article/183921/caixa-bancos-passo-a-passo-como-conciliar-saldo?ticketId=&ticketId=&q=&menuId=20355-0-183921',
    FINANCEIRO_CONTAS_A_PAGAR = 'https://ajuda.otk.com.br/kb/pt-br/article/183915/contas-a-pagar-passo-a-passo-como-inserir?ticketId=&ticketId=&q=&q=&menuId=20353-0-183915',
    FINANCEIRO_CONTAS_A_RECEBER = 'https://ajuda.otk.com.br/kb/pt-br/article/183898/contas-a-receber-passo-a-passo-como-inserir?ticketId=&ticketId=&q=&menuId=20352-0-183898',
    GRUPO_LANCAMENTO = 'https://ajuda.otk.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_bd6fe4a5-683d-4e75-9d15-8142836838d9',
    ITEM_LANCAMENTO = 'https://ajuda.otk.com.br/kb/pt-br/article/183890/classificacoes-financeiras-passo-a-passo-como-cadastrar?ticketId=&ticketId=&q=&q=&menuId=20351-0-183890#h_ed891c45-993f-4cea-9a25-9543ee9d4a10',
    PREVISAO_CONTAS_A_PAGAR = 'https://ajuda.otk.com.br/kb/pt-br/article/183918/previsao-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20354-0-183918',
    PREVISAO_CONTAS_A_RECEBER = 'https://ajuda.otk.com.br/kb/pt-br/article/183918/previsao-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20354-0-183918',
    FATURA = 'https://ajuda.otk.com.br/kb/article/183923/faturas-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&menuId=20357-0-183923',
    NOTA_FISCAL_PRODUTOS = 'https://ajuda.otk.com.br/kb/pt-br/article/183947/nota-fiscal-de-produto-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20361-0-183947',
    NOTA_FISCAL_SERVICOS = 'https://ajuda.otk.com.br/kb/pt-br/article/183945/nota-fiscal-de-servico-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20360-0-183945',
    PEDIDO_DE_VENDA = 'https://ajuda.otk.com.br/kb/pt-br/article/195916/vendas-pedido-de-venda-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=21713-0-195916',
    PREVISAO_FATURA = 'https://ajuda.otk.com.br/kb/pt-br/article/183943/previsao-nfs-e-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20359-0-183943',
    PREVISAO_SERVICOS = 'https://ajuda.otk.com.br/kb/pt-br/article/183943/previsao-nfs-e-passo-a-passo-como-fazer?ticketId=&ticketId=&q=&q=&menuId=20359-0-183943',
}
