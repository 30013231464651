import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'otk-cmp-back-button',
    templateUrl: './cmp-back-button.component.html',
    styleUrls: ['./cmp-back-button.component.scss'],
})
export class CmpBackButtonComponent implements OnInit {
    @Input() visible: boolean = true;
    @Input() customRoute?: string;

    constructor(private _location: Location, private _router: Router) {}

    ngOnInit() {}

    goBack() {
        if (this.customRoute) {
            this._router.navigate([this.customRoute]);
        } else {
            this._location.back();
        }
    }
}
