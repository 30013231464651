import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ActionButtonComponent } from './action-button/action-button.component';
import { AddButtonComponent } from './add-button/add-button.component';
import { FooterOptionButtonComponent } from './footer-option-button/footer-option-button.component';
import { ImportarButtonComponent } from './import-button/import-button.component';
import { OptionButtonComponent } from './option-button/option-button.component';
import { TutorialButtonComponent } from './tutorial-button/tutorial-button.component';
import { DxPopupModule } from 'devextreme-angular';

@NgModule({
    imports: [CommonModule, DxPopupModule],
    declarations: [
        TutorialButtonComponent,
        ImportarButtonComponent,
        ActionButtonComponent,
        AddButtonComponent,
        OptionButtonComponent,
        FooterOptionButtonComponent,
    ],
    exports: [
        TutorialButtonComponent,
        ImportarButtonComponent,
        ActionButtonComponent,
        AddButtonComponent,
        OptionButtonComponent,
        FooterOptionButtonComponent,
    ],
})
export class ButtonsComponentsGestioModule {}
