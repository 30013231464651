import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DevextremeComponentsModule } from '../devextreme-components.module';
import { MaterialComponentsModule } from '../material-components.module';
import { ButtonsComponentsModule } from '../ui-components/components/buttons/buttons-components.module';
import { CmpContentAreaGestioComponent } from './components/areas/content-area/cmp-content-area.component';
import { FirstStepsContentComponent } from './components/areas/first-steps-content/first-steps-content.component';
import { CmpPageAreaGestioComponent } from './components/areas/page-area/cmp-page-area.component';
import { ButtonsComponentsGestioModule } from './components/buttons/buttons-components.module';
import { DatagridComponentsGestioModule } from './components/datagrid/datagrid-components.module';
import { TabsGestioModule } from './components/tabs/tabs.module';
import { TextboxSearchGestioModule } from './components/textbox-search/textbox-search.module';
import { TextboxsComponentsGestioModule } from './components/textbox/textboxs-components.module';
import { CmpTitleAreaGestioComponent } from './components/title-area/cmp-title-area.component';

@NgModule({
    imports: [
        CommonModule,
        DevextremeComponentsModule,
        MaterialComponentsModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        ButtonsComponentsModule,
    ],
    declarations: [
        FirstStepsContentComponent,
        CmpContentAreaGestioComponent,
        CmpPageAreaGestioComponent,
        CmpTitleAreaGestioComponent,
    ],
    exports: [
        CmpContentAreaGestioComponent,
        FirstStepsContentComponent,
        CmpPageAreaGestioComponent,
        CmpTitleAreaGestioComponent,
        DatagridComponentsGestioModule,
        ButtonsComponentsGestioModule,
        TextboxsComponentsGestioModule,
        TabsGestioModule,
        TextboxSearchGestioModule,
    ],
})
export class UIComponentsGestioModule {}
