import {
    Component
} from '@angular/core';

@Component({
    selector: 'gestio-cmp-datagrid-footer-options',
    templateUrl: './cmp-datagrid-footer-options.component.html',
    styleUrls: ['./cmp-datagrid-footer-options.component.scss'],
})
export class CmpDatagridFooterOptionsGestioComponent {
    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}
}
