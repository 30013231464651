import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DxTextBoxModule } from 'devextreme-angular';

import { GestioTextboxSearchComponent } from './gestio-textbox-search.component';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, DxTextBoxModule],
    declarations: [GestioTextboxSearchComponent],
    exports: [GestioTextboxSearchComponent],
})
export class TextboxSearchGestioModule {}
