import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxTextBoxComponent } from 'devextreme-angular';

import { AppHelper } from '../../../app.helper';

@Component({
    selector: 'gestio-textbox-search',
    templateUrl: './gestio-textbox-search.component.html',
    styleUrls: ['./gestio-textbox-search.component.scss'],
})
export class GestioTextboxSearchComponent implements OnInit {
    @Input() initialValue: any = null;
    @Input() inputPlaceholder: string = 'Pesquisar...';
    @Input() control: FormControl = new FormControl(null, []);
    @Input() mask: string = null;
    @Input() width: string = null;
    @Input() location: string = 'before';
    @Input() showClearButton: boolean = true;
    @Output() searchEvent = new EventEmitter<string>();
    @Output() isSearchValidEvent = new EventEmitter<boolean>();
    @Output() valueChangedEvent = new EventEmitter<any>();
    @Output() enterKeyEvent = new EventEmitter<any>();
    @Output() keyUpEvent = new EventEmitter<any>();

    @ViewChild(DxTextBoxComponent) textBox: DxTextBoxComponent;

    searchButton: any;

    constructor() {
        this.searchButton = {
            icon: 'search',
        };
    }

    ngOnInit() {
        this.isSearchValidEvent.emit(false);
    }

    ngOnDestroy() {}

    ngOnChanges() {
        if (this.initialValue) {
            AppHelper.setCtrlValue(this.control, this.initialValue);
            setTimeout(() => {
                this.isSearchValidEvent.emit(true);
                this.searchEvent.emit(this.control.value);
            }, 300);
        } else {
            AppHelper.setCtrlValue(this.control, null);
        }
    }

    actionValueChanged(e: any) {
        this.valueChangedEvent.emit(e);

        if (!e) return;

        if (this.mask && this.textBox) {
            this.isSearchValidEvent.emit(this.textBox.isValid);
            if (this.textBox.isValid) {
                this.searchEvent.emit(e.value);
            }
            return;
        }

        this.isSearchValidEvent.emit(e.value ? true : false);
        this.searchEvent.emit(e.value);
    }

    actionEnterKey(e: any) {
        if (!e) {
            return;
        }
        this.enterKeyEvent.emit(this.control.value);
    }

    actionKeyUp(e: any) {
        if (!e || !e.event) {
            return;
        }

        if (e.event.key == 'Enter' || e.event.code == 13) {
            return;
        }

        this.keyUpEvent.emit(e);
    }
}
