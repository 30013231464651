import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxDateBoxModule,
    DxPopoverModule,
    DxTemplateModule,
} from 'devextreme-angular';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CmpDatagridFilterDateboxGestioComponent } from './cmp-datagrid-filter-datebox.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DxPopoverModule,
        DxTemplateModule,
        DxDateBoxModule,
    ],
    declarations: [CmpDatagridFilterDateboxGestioComponent],
    exports: [CmpDatagridFilterDateboxGestioComponent],
})
export class DatagridFilterDateboxGestioModule {}
