import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxDateBoxModule,
    DxPopoverModule,
    DxTemplateModule,
} from 'devextreme-angular';

import { FlexLayoutModule } from '@angular/flex-layout';
import { CmpDatagridFilterDateboxMonthYearLabelGestioComponent } from './cmp-datagrid-filter-datebox-month-year-label.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DxPopoverModule,
        DxTemplateModule,
        FlexLayoutModule,
        DxDateBoxModule,
    ],
    declarations: [CmpDatagridFilterDateboxMonthYearLabelGestioComponent],
    exports: [CmpDatagridFilterDateboxMonthYearLabelGestioComponent],
})
export class DatagridFilterDateboxMonthYearLabelGestioModule {}
