import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';

@Component({
    selector: 'otk-cmp-area-button',
    templateUrl: './cmp-area-button.component.html',
    styleUrls: ['./cmp-area-button.component.scss'],
})
export class CmpAreaButtonComponent implements OnInit, OnDestroy {
    @Input() title: string = '';
    @Input() subTitle: string = '';
    @Input() valorAdicional: string = '';
    @Input() valorAdicionalMensal: string = '';
    @Input() textButton: string = '';
    @Input() iconName: string = '';
    @Input() totalLicencas: number;

    @Input() enabled: boolean = true;
    @Output() onClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    actionBtnClick(e: any): void {
        this.onClick.emit(e);
    }
    ngOnDestroy(): void {}
}
