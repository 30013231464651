import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule, MatTooltipModule } from '@angular/material';

import { FlexLayoutModule } from '@angular/flex-layout';
import { DevextremeComponentsModule } from '../../../devextreme-components.module';
import { CmpDatagridFabToolbarGestioComponent } from './cmp-datagrid-fab-toolbar.component';
import { CmpDatagridFooterOptionsGestioComponent } from './cmp-datagrid-footer-options.component';
import { DatagridFilterDateboxGestioModule } from './datebox/datebox-label/cmp-datagrid-filter-datebox.module';
import { DatagridFilterDateboxMonthYearLabelGestioModule } from './datebox/datebox-month-year-label/cmp-datagrid-filter-datebox-month-year-label.module';
import { DatagridFilterDateboxYearLabelGestioModule } from './datebox/datebox-year-label/cmp-datagrid-filter-datebox-year-label.module';

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatButtonModule,
        MatTooltipModule,
        DevextremeComponentsModule,
    ],
    exports: [
        CmpDatagridFabToolbarGestioComponent,
        CmpDatagridFooterOptionsGestioComponent,
        DatagridFilterDateboxGestioModule,
        DatagridFilterDateboxMonthYearLabelGestioModule,
        DatagridFilterDateboxYearLabelGestioModule,
    ],
    declarations: [
        CmpDatagridFabToolbarGestioComponent,
        CmpDatagridFooterOptionsGestioComponent,
    ],
})
export class DatagridComponentsGestioModule {}
