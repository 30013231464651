import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { routeAnimation } from '../../../../route.animation';

@Component({
    selector: 'gestio-cmp-page-area',
    templateUrl: './cmp-page-area.component.html',
    styleUrls: ['./cmp-page-area.component.scss'],
    host: {
        '[@routeAnimation]': 'true',
    },
    animations: [routeAnimation],
})
export class CmpPageAreaGestioComponent implements OnInit, OnDestroy {
    public hasBanner: boolean = false;

    private _hasBannerSubscription$: Subscription;
    constructor(private _authService: AuthService) {}

    ngOnInit() {
        this._hasBannerSubscription$ = this._authService.hasBanner$
            .subscribe((value: boolean) => {
                this.hasBanner = value;
            });
    }

    ngOnDestroy() {
        this._hasBannerSubscription$.unsubscribe();
    }
}
