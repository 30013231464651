import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'gestio-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit {
    @Input() tabs: any[] = [];
    @Input() selectedIndex: number = 0;
    @Output() onItemClick = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    actionItemClick(event) {
        this.onItemClick.emit(event);
    }
}
