import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DxDateBoxComponent } from 'devextreme-angular';

import { hasRequiredField } from './../../../../../app.functions';

@Component({
    selector: 'gestio-cmp-datagrid-filter-datebox-year-label',
    templateUrl: './cmp-datagrid-filter-datebox-year-label.component.html',
    styleUrls: ['./cmp-datagrid-filter-datebox-year-label.component.scss'],
})
export class CmpDatagridFilterDateboxYearLabelGestioComponent implements OnInit {
    @Input() label: string = null;
    @Input() help: string = null;
    @Input() placeholder: string = '';
    @Input() control: FormControl = new FormControl(null, []);
    @Input() minDate: string = '1980/1/1';
    @Input() maxDate: string = '2050/12/31';
    @Input() width: string = '90px';
    @Input() widthHelp: string = '300px';
    @Input() showClearButton: boolean = true;
    @Input() acceptCustomValue: boolean = false;
    @Input() validationMessageMode: string = 'always';
    @Input() errorRequired: string = `Deve ser informado.`;
    @Input() errorInvalid: string = `Data inválida.`;
    @Input() maskInvalidMessage: string = `Valor inválido.`;
    @Input() noMarginBottom: boolean = false;

    @Output() onValueChanged = new EventEmitter<any>();

    popoverVisible: boolean;

    @ViewChild(DxDateBoxComponent) dateBox: DxDateBoxComponent;

    constructor() {}

    ngOnInit() {}

    ngOnDestroy() {}

    ngOnChanges() {}

    get _caption(): string {
        return this.label.replace(/\*/g, ' ');
    }

    get _captionLabel(): string {
        if (this.control && hasRequiredField(this.control)) {
            let t = this.label.replace(/\*/g, ' ');
            return `${t} *`;
        }
        return this.label;
    }

    isValid(): boolean {
        if (!this.control) {
            return false;
        }

        if (this.control.pristine && this.control.untouched) {
            return true;
        }

        return !this.control.invalid;
    }

    validationError(): any {
        if (!this.control) {
            return { message: null };
        }

        if (!this.control.errors) {
            return { message: null };
        } else {
            // console.log("CONTROL ERROR ->", this.control.errors, this.control.valid);
            if (this.control.errors.required) {
                return { message: this.errorRequired };
                // } else if (this.control.errors.minlength) {
                //     return { message: `Deve possuir no mínimo ${this.control.errors.minlength.requiredLength} caracteres.` };
                // } else if (this.control.errors.maxlength) {
                //     return { message: `Deve possuir no máximo ${this.control.errors.maxlength.requiredLength} caracteres.` };
            } else if (this.control.errors.invalidMask) {
                return { message: this.maskInvalidMessage };
            } else {
                return { message: `Inválido.` };
            }
        }
    }

    actionValueChanged(e: any) {
        if (e && e.value && this.dateBox) {
            this.onValueChanged.emit(e);

            if (!this.dateBox.isValid) {
                this.control.setErrors({ invalidMask: true });
            }
        }
    }

    togglePopover() {
        this.popoverVisible = !this.popoverVisible;
    }
}
