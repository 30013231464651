import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    DxDateBoxModule,
    DxPopoverModule,
    DxTemplateModule,
} from 'devextreme-angular';
import { CmpDatagridFilterDateboxYearLabelGestioComponent } from './cmp-datagrid-filter-datebox-year-label.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        DxPopoverModule,
        DxTemplateModule,
        DxDateBoxModule,
    ],
    declarations: [CmpDatagridFilterDateboxYearLabelGestioComponent],
    exports: [CmpDatagridFilterDateboxYearLabelGestioComponent],
})
export class DatagridFilterDateboxYearLabelGestioModule {}
